<template>
  <div class="list__info list-info callback-edit-form">
    <form @submit.prevent="onProvidersAdd">
      <div class="group__title text--blue">
        {{ $t("editCallback") }}
      </div>
      <div class="group">
        <div class="group__title">{{ $t("clientLastName") }}</div>
        <div class="group__content">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('clientLastName')"
            v-model="lastname"
          />
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("clientFirstName") }}</div>
        <div class="group__content">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('clientFirstName')"
            v-model="firstname"
          />
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("clientMiddleName") }}</div>
        <div class="group__content">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('clientMiddleName')"
            v-model="middlename"
          />
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("phone") }}</div>
        <div class="group__content">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('phone')"
            v-model="phone"
          />
        </div>
      </div>
      <div class="group group--region">
        <div class="group__title">{{ $t("region") }}</div>
        <div class="group__content">
          <select class="form-select" name="region" v-model="region">
            <option
              v-for="(item, index) in regions"
              :selected="
                editedItem ? item.value === editedItem.region.value : false
              "
              :value="item"
            >
              {{ item.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("category") }}</div>
        <div class="group__content">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('category')"
            v-model="category.categoryName"
          />
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("description") }}</div>
        <div class="group__content">
          <textarea class="form-textarea" :placeholder="$t('description')">
            {{ message }}
          </textarea>
        </div>
      </div>
      <div class="group__title text--blue">
        {{ $t("executor") }}
      </div>
      <div class="group">
        <div class="group__title">{{ $t("fioExecutor") }}</div>
        <div class="group__content">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('fioExecutor')"
          />
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("status") }}</div>
        <div class="group__content">
          <select
            class="form-select"
            name="status"
            v-model="status"
            :disabled="!status"
          >
            <option v-for="(item, index) in statusList" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("orderNumber") }}</div>
        <div class="group__content"></div>
      </div>
      <div class="group">
        <div class="group__title">{{ $t("comment") }}</div>
        <div class="group__content">
          <textarea class="form-textarea" :placeholder="$t('comment')">
            {{ comment }}
          </textarea>
        </div>
      </div>
      <v-button red>{{ $t("save") }}</v-button>
    </form>
  </div>
</template>

<script>
import VButton from "@/components/VButton";
import axios from "@/api/axios";
import { mapMutations } from "vuex";

export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fio: "",
      currentInput: "",
      categories:
        this.editedItem && this.editedItem.categories
          ? this.editedItem.categories
          : [],
      firstname:
        this.editedItem && this.editedItem.client.name
          ? this.editedItem.client.name
          : "",
      lastname:
        this.editedItem && this.editedItem.client.surname
          ? this.editedItem.client.surname
          : "",
      middlename:
        this.editedItem && this.editedItem.client.lastname
          ? this.editedItem.client.lastname
          : "",
      phone:
        this.editedItem && this.editedItem.phone ? this.editedItem.phone : "",
      orderNumber:
        this.editedItem && this.editedItem.orderNumber
          ? this.editedItem.orderNumber
          : null,
      category:
        this.editedItem && this.editedItem.category
          ? this.editedItem.category.category
          : null,
      issuedTo:
        this.editedItem && this.editedItem.issuedTo
          ? this.editedItem.issuedTo
          : null,
      issuedBy:
        this.editedItem && this.editedItem.issuedBy
          ? this.editedItem.issuedBy
          : null,
      message:
        this.editedItem && this.editedItem.message
          ? this.editedItem.message
          : "",
      comment:
        this.editedItem && this.editedItem.comment
          ? this.editedItem.comment
          : "",
      status:
        this.editedItem && this.editedItem.status
          ? this.editedItem.status
          : null,
      statusList: ["отказ", "подтвержденный"],
      users: [],
      regions: [],
      region:
        this.editedItem && this.editedItem.region
          ? this.editedItem.region
          : null,
      title: this.editedItem ? "Редактировать обращения" : "Добавить обращения",
      confirmMsg: {
        header: "Вы уверены?",
        message: "Подтвердите действие.",
      },
      dialog: {
        header: "",
        message: "",
        callback: null,
        args: [],
      },
    };
  },
  components: { VButton },
  methods: {
    ...mapMutations({
      changeStatus: "change_load_status",
    }),
    onProvidersAdd() {
      if (!this.region) {
        this.$toast.error("Укажите регион!", "Ошибка");
        return;
      }
      this.changeStatus(false);
      let data = {
        callissue: {
          firstname: this.firstname,
          lastname: this.lastname,
          middlename: this.middlename,
          issuedBy: this.issuedBy,
          issuedTo: this.issuedTo,
          status: this.status,
          phone: this.phone,
          orderNumber: this.orderNumber,
          category: this.category,
          region: this.region,
          message: this.message,
          comment: this.comment,
        },
      };
      return;
      if (this.editedItem) {
        data.dataId = this.editedItem._id;
        if (!this.orderNumber && this.issuedBy._id !== this.currentUser._id) {
          this.changeStatus(true);
          return this.$toast.warning("№ заказа обязательно для заполнения!");
        }
        axios({
          url: "/callcenterissues/update/",
          data: data,
          method: "POST",
        })
          .then(async (res) => {
            if (res.data.message === "ORDEREXISTS") {
              this.changeStatus(true);
              return this.$toast.warning("№ заказа существует!");
            }
            const transformedData = {
              _id: data.dataId,
              ...data.callissue,
              category: {
                category: {
                  ...data.callissue.category,
                },
              },
              client: {
                name: data.callissue.firstname,
                surname: data.callissue.lastname,
                lastname: data.callissue.middlename,
              },
              createdAt: this.editedItem.createdAt,
              confirmedAt: this.editedItem.confirmedAt,
            };
            this.$emit("editForm", transformedData);

            this.$toast.success("Обращение успешно обновлено!");
            this.$emit("toggleOpen");
            this.changeStatus(true);
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
            this.changeStatus(true);
          });
      } else {
        data.callissue.issuedBy = this.currentUser;
        axios({
          url: process.env.VUE_APP_DEVELOP_URL + `/callcenterissues/post/`,
          data: data,
          method: "POST",
        })
          .then(async (res) => {
            const createdData = await res;
            this.$emit("addCallIssue", {
              ...data.callissue,
              _id: createdData.data.data._id,
              number: createdData.data.data.number,
              issuedBy: this.currentUser,
              category: {
                category: {
                  ...data.callissue.category,
                },
              },
              client: {
                name: data.callissue.firstname,
                surname: data.callissue.lastname,
                lastname: data.callissue.middlename,
              },
              createdAt: createdData.data.data.createdAt,
            });
            this.$toast.success("Обращения успешно добавлен!");
            this.$emit("toggleOpen");
            this.changeStatus(true);
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
            this.changeStatus(true);
          });
      }
    },
  },
  watch: {
    currentInput: function () {
      if (this.region === null) {
        this.$toast.warning("Укажите регион!", "Ошибка");
      }
    },
    region: async function () {
      if (this.region !== null) {
        this.currentInput = "";
        await axios({
          url: "/categories/get/",
          data: {
            options: {
              nesting: 0,
              region: this.region._id,
            },
          },
          method: "POST",
        }).then(async (res) => {
          this.category = null;
          this.categories = res.data.categories;
        });
      }
    },
  },
  async created() {
    await axios({
      url: "/regions/get",
    }).then(async (res) => {
      this.regions = res.data.regions;
    });

    if (this.editedItem) {
      await axios({
        url: "/categories/get/",
        data: {
          options: {
            nesting: 0,
            region: this.region._id,
          },
        },
        method: "POST",
      }).then(async (res) => {
        this.editedItem.categories = res.data.categories;
        this.categories = res.data.categories;
        this.tempViews = res.data.categories;
      });
    }
  },
  computed: {
    currentUser: {
      get: function () {
        return this.getUserRole();
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_variables";

.callback-edit-form {
  .group {
    max-width: 976px;

    select {
      max-width: 401px;
    }
  }

  form > .group__title {
    position: relative;
    padding-top: 10px;

    &::before {
      display: block;
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      top: 0;
      background-color: $color-gray-secondary;
      border-radius: $border-radius;
    }
  }
}
</style>
